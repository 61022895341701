import { apiGetData } from '@/services/ApiService.js'

const state = () => ({
  items: [],
  bookmarks: [],
  count: 0,
  userInteract: false,
})

const actions = {
  /* Load Data */

  loadBookmarksCount({ commit }) {
    apiGetData('store/bookmarks/count').then((response) => {
      commit('LOAD_BOOKMARKS_COUNT', response)
    })
  },
  setBookmarksData({ commit }, bookmarks) {
    commit('SET_BOOKMARKS_DATA', bookmarks)
  },

  /* User Interactions */
  addToBookmarks({ commit, dispatch }, productId) {
    return apiGetData('store/bookmarks/add/' + productId)
      .then((response) => {
        if (response.success) {
          commit('ADD_TO_BOOKMARKS', productId)
          dispatch('loadBookmarksCount', false)
        }
        return response.success // resolve
      })
      .catch((err) => {
        throw err // reject
      })
  },
  removeToBookmarks({ commit, dispatch }, productId) {
    return apiGetData('store/bookmarks/remove/' + productId)
      .then((response) => {
        if (response.success) {
          commit('REMOVE_TO_BOOKMARKS', productId)
          dispatch('loadBookmarksCount', false)
        }
        return response.success // resolve
      })
      .catch((err) => {
        throw err // reject
      })
  },

  /* Paginator */
  changeCurrentPage({ commit, dispatch }, pageNumber) {
    dispatch('paginator/changeCurrentPage', pageNumber, { root: true })
    commit('REGISTER_INTERACT')
  },
}

const mutations = {
  LOAD_BOOKMARKS_COUNT(state, count) {
    state.count = count
  },
  SET_BOOKMARKS_DATA(state, bookmarks) {
    state.bookmarks = []
    state.bookmarks = bookmarks
  },
  REGISTER_INTERACT(state) {
    state.userInteract = !state.userInteract
  },

  ADD_TO_BOOKMARKS(state, productId) {
    state.items.push(productId)
  },
  REMOVE_TO_BOOKMARKS(state, productId) {
    state.items.splice(productId)
  },
}

const getters = {
  getBookmarksCount: (state) => {
    return state.count
  },
  getBookmarks: (state) => {
    return state.bookmarks
  },
  getUserInteract: (state) => {
    return state.userInteract
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
