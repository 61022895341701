const state = () => ({
  notificationsQueue: [],
  pages: [],
  fields: [],
})

const actions = {
  addNotification({ commit, dispatch }, notification) {
    notification.id = (
      Math.random().toString(36) + Date.now().toString(36)
    ).substring(2)

    commit('ADD_NOTIFICATION', notification)
    setTimeout(() => {
      dispatch('deleteNotification', notification.id)
    }, notification.ttl)
  },
  addNotifications({ dispatch }, notifications) {
    notifications.forEach(function (notification) {
      dispatch('addNotification', notification)
    })
  },
  addFields({ commit }, fields) {
    commit('ADD_FIELDS', fields)
  },

  addPages({ commit }, pages) {
    commit('ADD_PAGES', pages)
  },

  clearErrorMarkers({ commit }) {
    commit('CLEAR_ERRORS_MARKERS')
  },

  addPages({ commit }, pages) {
    commit('ADD_PAGES', pages)
  },

  deleteNotification({ commit }, notificationId) {
    commit('DELETE_NOTIFICATION', notificationId)
  },
}

const mutations = {
  ADD_NOTIFICATION(state, notification) {
    if (
      state.notificationsQueue.filter((currentNotification) => {
        return currentNotification.message == notification.message
      }).length === 0
    ) {
      state.notificationsQueue.push(notification)
    }
  },

  DELETE_NOTIFICATION(state, notificationId) {
    state.notificationsQueue = state.notificationsQueue.filter(
      (notification) => {
        return notification.id != notificationId
      }
    )
  },

  ADD_FIELDS(state, fields) {
    if (fields.length > 0) state.fields = fields
  },

  ADD_PAGES(state, pages) {
    if (pages.length > 0) state.pages = pages
  },

  CLEAR_ERRORS_MARKERS(state) {
    state.fields = []
    state.pages = []
  },
}

const getters = {
  getNotificationsQueue: (state) => {
    return state.notificationsQueue
  },
  getFields: (state) => {
    return state.fields
  },
  checkField: (state) => (_field) => {
    return (
      state.fields.filter((field) => {
        return field == _field
      }).length > 0
    )
  },
  checkPage: (state) => (_page) => {
    return (
      state.pages.filter((page) => {
        return page == _page
      }).length > 0
    )
  },
  getPages: (state) => {
    return state.pages
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
