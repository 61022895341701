const state = () => ({
  userInteract: false,
  currentPage: 1,

  itemsCount: 0,
  pagesCount: 0,
  init: 0,
  end: 0,
})

const actions = {
  changeCurrentPage({ commit }, pageNumber) {
    commit('CHANGE_CURRENT_PAGE', pageNumber)
    commit('REGISTER_INTERACT')
  },
  setPaginatorData({ commit }, paginatorData) {
    commit('SET_PAGINATOR_DATA', paginatorData)
  },
  clearPaginatorState({ commit }) {
    commit('CLEAR_PAGINATOR_STATE')
  },
}

const mutations = {
  CLEAR_PAGINATOR_STATE(state) {
    state.currentPage = 1
    state.itemsCount = 0
    state.pagesCount = 0
    state.init = 0
    state.end = 0
  },
  CHANGE_CURRENT_PAGE(state, pageNumber) {
    state.currentPage = pageNumber
  },
  SET_PAGINATOR_DATA(state, paginatorData) {
    state.itemsCount = paginatorData.itemsCount
    state.pagesCount = paginatorData.pagesCount
    state.init = paginatorData.init
    state.end = paginatorData.end
  },
  REGISTER_INTERACT(state) {
    state.userInteract = !state.userInteract
  },
}

const getters = {
  getCurrentPage: (state) => {
    return state.currentPage
  },
  getPagesCount: (state) => {
    return state.pagesCount
  },
  getItemsCount: (state) => {
    return state.itemsCount
  },
  getItemsInit: (state) => {
    return state.init
  },
  getItemsEnd: (state) => {
    return state.end
  },
  getUserInteract: (state) => {
    return state.userInteract
  },
  getPaginatorArray: (state) => {
    let pagesArray = []
    let pageSize = 5
    let rLimit = state.pagesCount - pageSize + 1
    let x = 1
    let y = state.pagesCount

    if (state.pagesCount > pageSize) {
      x = state.currentPage - 2
      y = state.currentPage + 2

      if (x < 1) x = 1
      if (x > rLimit) x = rLimit

      if (y < pageSize) y = pageSize
      if (y > state.pagesCount) y = state.pagesCount
    }

    for (let i = x; i <= y; i++) {
      pagesArray.push(i)
    }

    return pagesArray
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
