const state = () => ({
  fullscreenState: false,
  scrollState: false,
  mainMenuState: false,
  clientSize: { width: 0, height: 0 },
  mainMenuSize: 'sm',
})

const actions = {
  /* Menu */

  toggleMainMenu({ commit, getters }) {
    commit('TOGGLE_MAIN_MENU', getters)
  },

  /* Window */

  setClientSize({ commit }, clientSize) {
    commit('SET_CLIENT_SIZE', clientSize)
  },

  setScrollState({ commit }, status) {
    commit('SET_SCROLL_STATE', status)
  },

  toggleFullScreen({ commit }) {
    commit(
      'SET_FULL_SCREEN_MODE',
      !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
    )
  },
}

const mutations = {
  /* Menu */

  TOGGLE_MAIN_MENU(state, getters) {
    state.mainMenuState = !state.mainMenuState

    let clientWidth = getters.getClientSize.width

    //console.log(clientWidth)

    if (clientWidth < 1025 && clientWidth > 767) {
      document.body.classList.remove('vertical-sidebar-enable')

      state.mainMenuSize = state.mainMenuSize == 'sm' ? '' : 'sm'
    } else if (clientWidth > 1025) {
      document.body.classList.remove('vertical-sidebar-enable')

      state.mainMenuSize = state.mainMenuSize == 'lg' ? 'sm' : 'lg'
    } else if (clientWidth <= 767) {
      document.body.classList.add('vertical-sidebar-enable')

      state.mainMenuSize = 'lg'
    }

    document.documentElement.setAttribute(
      'data-sidebar-size',
      state.mainMenuSize
    )
  },

  /* Window */

  SET_CLIENT_SIZE(state, clientSize) {
    state.clientSize.width = clientSize.width
    state.clientSize.height = clientSize.height
  },

  SET_SCROLL_STATE(state, status) {
    state.scrollState = status
  },

  SET_FULL_SCREEN_MODE(state, status) {
    state.fullscreenState = status

    if (status) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  },
}

const getters = {
  getClientSize: (state) => {
    return state.clientSize
  },
  getMainMenuState: (state) => {
    return state.mainMenuState
  },
  getMainMenuSize: (state) => {
    return state.mainMenuSize
  },
  getScrollState: (state) => {
    return state.scrollState
  },
  getFullscreenState: (state) => {
    return state.fullscreenState
  },
  getState: (state) => {
    return state
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
