import { apiGetData, apiPostData } from '@/services/ApiService.js'

const state = () => ({
  systems: [],
  rootGroups: [],
})

const actions = {
  // General

  loadSystems({ commit }) {
    apiGetData('auth/systems/populate').then((response) => {
      commit('LOAD_SYSTEMS', response)
    })
  },

  loadRootGroups({ commit }) {
    apiGetData('auth/root-groups/populate').then((response) => {
      commit('LOAD_ROOT_GROUPS', response)
    })
  },
}

const mutations = {
  LOAD_SYSTEMS(state, data) {
    state.systems = data ?? []
  },
  LOAD_ROOT_GROUPS(state, data) {
    state.rootGroups = data ?? []
  },
}

const getters = {
  getSystems: (state) => {
    //return state.systems
    return [{ id: 0, name: 'Selecione...' }, ...state.systems]
  },
  getRootGroups: (state) => {
    //return state.rootGroups
    return [{ id: 0, name: 'Selecione...' }, ...state.rootGroups]
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
